html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
pre,
ul {
  margin: 0;
  padding: 0;
}

body,
button,
input {
  font-family: Inter, sans-serif;
  font-size: 16px;
}

body {
  color: #242529;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button {
  cursor: pointer;
}

a {
  color: #2a9d8f;
  text-decoration: none;
  transition: color 0.3s;
}
a:hover {
  color: #007e71;
}

.wrapper {
  margin: 0 auto;
  max-width: 920px;
}

.container {
  margin: 24px auto;
}

.header {
  text-align: center;
}
.header__title {
  margin: 0 0 8px;
  font-size: 36px;
  font-weight: 800;
}
.header__desc {
  font-size: 16px;
}

.main {
  flex-grow: 1;
  width: 100%;
}

.footer {
  text-align: center;
}
.footer .footer__love {
  color: #f94144;
  font-size: 20px;
  transition: color 0.3s;
  position: relative;
  top: 1px;
}
.footer .footer__love:hover {
  color: #d4122a;
}

.input-field:not(:last-child) {
  margin-bottom: 16px;
}
.input-field .label {
  font-weight: 600;
}
.input-field .label .input {
  margin-top: 10px;
}
.input-field .input {
  display: block;
  border: 1px solid #242529;
  border-radius: 8px;
  padding: 13px 16px;
  transition: 0.2s;
  width: 100%;
}
.input-field .input::placeholder {
  color: rgba(47, 50, 84, 0.5);
}
.input-field .input:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}
.input-field .input:active, .input-field .input:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border: 1px solid #4c4c4c;
  outline: none;
}

.input-form {
  max-width: 360px;
}

.button {
  text-transform: uppercase;
  color: #13102c;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  letter-spacing: 3.5px;
  transition: background-color 0.3s;
}
.button.button-black {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  background-color: #2f3254;
  border: none;
  border-radius: 46px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  font-weight: 600;
  font-size: 16px;
  padding: 0 40px;
}
.button.button-black:hover, .button.button-black:active {
  background-color: #26294b;
}

.output {
  display: flex;
  margin: -8px;
}
.output__column {
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  border: 1px solid #242529;
  border-radius: 8px;
}
.output__header {
  color: #2a9d8f;
}
.output__payload {
  color: #f4a261;
}
.output__signature {
  color: #e76f51;
}
.output__raw, .output__json {
  font-size: 16px;
  word-wrap: anywhere;
  white-space: pre-wrap;
  word-break: break-all;
}
.output__json :not(:last-child) {
  margin-bottom: 16px;
}

.users {
  list-style: none;
}
.users__item * {
  vertical-align: middle;
}
.users__item :not(:last-child) {
  margin-right: 8px;
}
.users__id {
  font-family: monospace;
}
.users__label {
  color: rgba(47, 50, 84, 0.5);
}
.users__remove {
  color: #e76f51;
  cursor: pointer;
  font-weight: bold;
  font-size: 22px;
  transition: color 0.3s;
}
.users__remove:hover {
  color: #c24f34;
}

.users-hint {
  color: rgba(47, 50, 84, 0.5);
  font-size: 13px;
  margin-top: 16px;
}
/*# sourceMappingURL=index.499bff89.css.map */
